import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import ShiftyPage from "src/components/shiftyPage"
import Btf_cb from "src/components/btf_cb"
import ClearDisclosure from "@tightrope/disclaimers"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const shiftylpData = import("./data/data.json");
const btfData = import("./data/btfData.json");
const footer = <RawFooter language='clear'></RawFooter>;

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
      #disclaimers-module--disclaimers p {
        text-align: center;
        color: #666;
        font-size: 13px;
        width: 466px;
        line-height: 150%;
        margin: 0 auto 12px;
      }
.btf_cb-module--btfCb .btf_cb-module--bf {
background-image: linear-gradient(180deg, rgba(242, 249, 255, 1) 0%, rgba(242, 249, 255, 1) 90%, rgba(242, 249, 255, 0) 100%);
     }
.btf_cb-module--btfCb .btf_cb-module--freedom {
background:#fff;
}
.btf_cb-module--btfCb button.btf_cb-module--install {
    background-color: #3b82f6;
    border: 0;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    font-size: 46px;
    min-width: 679px;
    padding: 37.55px 13px;
}

.btf_cb-module--btfCb button.btf_cb-module--install:hover {
    background-color: #295bac;
    transform:none;
}

#disclaimers-module--disclaimers p {
    font-size:14px;
    color: #2f3438;
}

      footer {
        font-family: 'Roboto', sans-serif;
        position: relative;
        width: 100%;
        padding: 16px 0;
        margin:0;
      }
      footer ul {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        list-style: none;
        color: #333;
        font-size: 12px;
        }
        footer ul li {
          padding:0 6px 0 0;
        }

        footer ul li a {
          color:#333;
        }

        footer ul li:last-child {
          padding:0;
        }

        footer ul li:after {
          content: '|';
          padding:0 0 0 6px;
        }

        footer ul li:last-child:after {
          content:none;
          padding:0;
        }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Find Manuals to Read & Print | manualslibrary.co</title></Helmet>
    <section>
      <ShiftyPage data={shiftylpData}>
        <ClearDisclosure language='en-clear-chrome'></ClearDisclosure>
      </ShiftyPage>
    </section>
    <Btf_cb data={btfData} footer={footer}>
      <ClearDisclosure language='en-clear-chrome'></ClearDisclosure>
    </Btf_cb>
    </HomepageLayout>
  )
}
